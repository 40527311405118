var $;
$ = require('jquery');
require("./main.scss");

    $(window).on('load',function(){

        $(".toggle-nav").on("click", function (e) {
            e.preventDefault();
            e.defaultPrevented;
    
            if ($(".menu").is(":visible")) {
                $(".menu").fadeOut(300);
            } else {
                $(".menu").css('display', 'flex').hide().fadeIn(300);
                //para poner flex: css('display','flex').hide()
            }
    
            $(".toggle-nav").toggleClass("animate-show");
        });

        $(".anchor").on("click",function(e){
            if($(".toggle-nav").is(":visible")){
                $(".menu").fadeOut(300);
                // let anchor = $(this).attr('href').split("#")[1];
                // if($("#"+anchor).length>0){
                    $(".toggle-nav").removeClass("animate-show");
                // }
            }
        });

    })

    

    // // $(".menu-main-container a").click(function(e){
    // // if($(".menu-main-container").is(":visible") && $(".toggle-nav").is(":visible")){
    // //     $(".toggle-nav").removeClass("animate-show");
        
    // //   }
    // // });

   

    $(window).on("resize",function () {
        $(".toggle-nav").removeClass("animate-show");
        $(".menu").removeAttr("style");
    });

    // $('.sub-menu a, .smooth-scroll a').click(function(e){

    //     e.preventDefault();

    //     let href = $(this).attr('href').split("/");

    //     if(href.length>1){

    //         let url = href[href.length-1];
    //         let page = href[href.length-2];

    //         if(window.location.href.search(page)>0){
    //             let anchor = url.split("#")[1];
    //             $("html, body").animate({ scrollTop: $("#"+anchor).offset().top }, 800);
    //             window.location.hash = "#"+anchor;

    //         } else {
    //             window.location = $(this).attr('href');
    //         }
    //     } else {
    //         $("html, body").animate({ scrollTop: $($(this).attr('href')).offset().top }, 800);
    //     }

    // });

    // $('.menu-item-has-children').click(function(e){

    //     if(e.target !== this){
    //         return
    //     }

    //     if($(".toggle-nav").is(":visible")){
    //         if(parseInt($(this).css('max-height')) < 60){
    //             $('.menu-item-has-children').css('max-height','50px');
    //             $(this).css('max-height','200px');
    //             $(this).addClass('chevron-down');
    //         } else {
    //             $(this).css('max-height','50px');
    //             $(this).removeClass('chevron-down');
    //         }
    //     }
        
    // });
    



